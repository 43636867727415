exports.components = {
  "component---src-pages-carreira-js": () => import("./../../../src/pages/carreira.js" /* webpackChunkName: "component---src-pages-carreira-js" */),
  "component---src-pages-clientes-js": () => import("./../../../src/pages/clientes.js" /* webpackChunkName: "component---src-pages-clientes-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-empresa-equipa-js": () => import("./../../../src/pages/empresa/equipa.js" /* webpackChunkName: "component---src-pages-empresa-equipa-js" */),
  "component---src-pages-empresa-grupo-js": () => import("./../../../src/pages/empresa/grupo.js" /* webpackChunkName: "component---src-pages-empresa-grupo-js" */),
  "component---src-pages-empresa-sobre-nos-js": () => import("./../../../src/pages/empresa/sobre-nos.js" /* webpackChunkName: "component---src-pages-empresa-sobre-nos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-special-us-js": () => import("./../../../src/pages/special-us.js" /* webpackChunkName: "component---src-pages-special-us-js" */),
  "component---src-templates-areas-negocio-js": () => import("./../../../src/templates/areas-negocio.js" /* webpackChunkName: "component---src-templates-areas-negocio-js" */),
  "component---src-templates-noticias-js": () => import("./../../../src/templates/noticias.js" /* webpackChunkName: "component---src-templates-noticias-js" */),
  "component---src-templates-o-nosso-menu-js": () => import("./../../../src/templates/o-nosso-menu.js" /* webpackChunkName: "component---src-templates-o-nosso-menu-js" */)
}

